<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-startablish">
        <a href="https://startablish.com/" target="_blank">
          <img src="@/assets/casestudy/startablish-logo.svg" alt="Startablish"></a>
        <span class="sep"></span>
        <p>Startablish is the platform for everyone who is looking for hard facts, data and
          background knowledge. For insiders who prefer to analyse data rather than empty
          phrases. For professionals who rely not on estimates but on concrete figures. For
          all those who believe in a sustainable startup scene in Europe and want to make
          their contribution to it. The Startablish startup data platform shows trends in the
          European startup scene, compares countries and sectors and makes it possible to
          search specifically for technologies and innovations.</p>
      </div>
    </div>

    <div class="laptop-img startablish-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/startablish-preview.png" alt="Startablish">
      </div>
    </div>

    <div class="container-about startablish-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/startablish-diamond.svg" alt="Startablish">
        <h3>About the project</h3>
        <p>Startablish's goal is to bring more transparency into startup business.
          On this platform everyone can create a user account and from there can analyse
          data. <br/>Every startup has a background, we are displaying location info, numbers,
          rankings daily updated from European startup data so you can monitor and compare
          innovations and technologies.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>

          <div class="font-typo">
            <img src="@/assets/casestudy/lato-font.svg" alt="Startablish Font">
            <h3><span style="color:#5078a8">Co</span><span style="color:#8b96a3">lo</span><span
                style="color:#212529">rs</span></h3>

            <div class="colors">
              <div class="fcolor startablish-p">Primary</div>
              <div class="scolor startablish-s">Secondary</div>
              <div class="tcolor startablish-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>

            <div class="color-codes">
              <div class="ccodes">
                <p class="startablish-primary">#5078a8</p>
                <p class="startablish-secondary">#8b96a3</p>
                <p class="startablish-third">#212529</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'MStar'}" class="left">
              <img src="@/assets/casestudy/btn-mstar.png" alt="Mstar">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Fyber'}" class="right">
              <img src="@/assets/casestudy/btn-fyber.jpg" alt="Fyber">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Startablish",
  components: {LetsChat, Footer, ContactUs}
}
</script>

<style lang="scss">
.logo-startablish img {
  max-width: 320px;
}

.startablish-laptop {
  background-color: #5078a8;
}

.startablish-about {
  background: #f0f0f0;
}

.startablish-p {
  background-color: #5078a8;
}

.startablish-primary {
  color: #5078a8;
}

.startablish-s {
  background-color: #8b96a3;
}

.startablish-secondary {
  color: #8b96a3;
}

.startablish-t {
  background-color: #212529;
}

.startablish-third {
  color: #212529;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #5078a8 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>